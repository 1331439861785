import type { ItemModel } from 'o365-dataobject';
import { DataObject } from 'o365-dataobject';
import { FileUpload } from 'o365-fileupload';
import { $t } from 'o365-utils';
//import { SocketClient } from 'o365-modules';
import { SignalRProcess } from './DataObject.SignalRProcess.ts'

async function alert(...args: any[]) {
    import('o365-vue-services').then(services => {
        services.alert(...args);
    });
}

declare module "o365-dataobject" {
    interface DataObject<T extends ItemModel = ItemModel> {
        _importTemplate: DataObjectImportTemplate<T>;
        importTemplate: DataObjectImportTemplate<T>
        /** Custom route for import tempalte processing */
        importTemplateProcessRouteUrl?: string
    }
}

Object.defineProperty(DataObject.prototype, "importTemplate", {
    get: function importTemplate() {
        if (!this._importTemplate) this._importTemplate = new DataObjectImportTemplate(this);

        return this._importTemplate;
    }
});


class DataObjectImportTemplate<T extends ItemModel = ItemModel>{
    private _dataObject: DataObject<T>;
    private _fileUpload: FileUpload;
    private _signalR: SignalRProcess;
    // private _socket: SocketClient;

    beforeImport: Function | undefined;
    onCompleted: Function | undefined;
    onProgress: Function | undefined;
    // progress: ProgressHandler;
    importStates: ImportStates | undefined | null;
    completed: boolean = false;
    rowsFailed: number = 0;
    currentGroupKey: string = "";
    error: string | null = null;


    constructor(pDataObject: DataObject<T>) {
        this._dataObject = pDataObject;
        this._fileUpload = new FileUpload({
            // url: `/api/workbook-import/chunkupload`,
            chunkUrl: (this._isBehindProxy() ? '/nt/' : '/') + `api/workbook-import/chunkupload`,
            useChunks: true,
            viewName: this._dataObject.viewName
        });


        // const route = this._dataObject.importTemplateProcessRouteUrl ?? `/api/process-hub/${this._dataObject.uniqueTable ?? this._dataObject.viewName}`;
        //   this._socket = new SocketClient((this._isBehindProxy()?'nt/':'')+route);
    }

    cancel() {
        this.completed = false;

        // this.progress.message = `${$t('Canceling upload')}`;
        this._fileUpload.abort();
        this._signalR.stopConnection();
    }

    private _getUplaodProgress(pFile: File, pUploaded: number) {
        return Math.min(Math.round(pFile.size / pUploaded * 100), 100);
    }

    async importData(pOptions: any) {
        let vBeforeUploadParams: any;
        this.importStates = new ImportStates();
        this.completed = false;
        let total = 0;
        this.error = null;
        this.rowsFailed = 0;

        try {
            pOptions.onProgress = (pProgress: any) => {
                this.importStates?.setMessage(`${this._getUplaodProgress(pOptions.file, pProgress.loaded)}%`);
            }

            this.importStates.setActiveByName("Uploading");
            const res = await this._fileUpload.upload(pOptions);
            this.importStates.setActiveByName("Parsing");
            const vOptions: any = {
                path: res.ResponseData.path,
                fileName: res.ResponseData.fileName,
                values: {},
                fields: this._dataObject.fields.getAllFields(),
                uniqueField: this._dataObject.fields.uniqueField,
                viewName: this._dataObject.viewName,
                uniqueTable: this._dataObject.uniqueTable ?? this._dataObject.viewName,
                batch: pOptions.batch
            }

            if (pOptions.beforeImport) vBeforeUploadParams = pOptions.beforeImport.call(this, vOptions, ...arguments);
            this._dataObject.fields.getAllFields().forEach((obj: any) => {
                if (obj.defaultValue !== undefined)
                    vOptions.values[obj.name] = obj.defaultValue;
            });

            if (vBeforeUploadParams && typeof vBeforeUploadParams == "object") {

                Object.keys(vBeforeUploadParams).forEach(key => {
                    vOptions.values[key] = vBeforeUploadParams[key];
                })

            }
            vBeforeUploadParams = this._dataObject.emit('BeforeImportTemplate', vOptions);
            if (vBeforeUploadParams && typeof vBeforeUploadParams == "object") {

                Object.keys(vBeforeUploadParams).forEach(key => {
                    vOptions.values[key] = vBeforeUploadParams[key];
                })

            }



            if (this._dataObject.masterDetails.isSet) {
                vOptions.values = { ...vOptions.values, ...this._dataObject.masterDetails.getMasterDetailRowForInsert() };
            }


            this._signalR = new SignalRProcess(this._dataObject.importTemplateProcessRouteUrl ?? '/api/process-hub');
            this._signalR.executeSqlOverSignal(vOptions, (pProgres: any) => {
                if (pProgres) {
                    if (pProgres.error) {
                        if (pOptions.onError) pOptions.onError.call(this, pProgres.error, ...arguments);
                        alert(pProgres.error);
                    }
                    if (pProgres.message) {
                        this.importStates?.setMessage($t(pProgres.message));

                    }
                    if (pProgres.total) {

                        total = pProgres.total;
                        this.importStates?.setMessage(`0 ${$t("out of")} ${total}`);

                    }
                    if (pProgres.current) {
                        this.importStates?.setMessage(`${pProgres.current + 1} ${$t("out of")} ${total}`);
                    }
                    if (pProgres.committing && this.importStates &&  this.importStates.activeState) {

                        this.importStates.activeState.customMessage = ` ${total} ${$t('rows')} `;
                        if (pProgres.batch) {
                            this.importStates.activeState.customMessage = ` ${total} ${$t('rows')} `;
                        }
                        this.importStates.setActiveByName("Committing");
                        if (pProgres.batch) {
                            this.importStates.activeState.customMessage = ` ${$t('bulk')} ${pProgres.total} ${$t('rows')} `;
                        }

                    }
                    if (pProgres.hasOwnProperty('rowsSucceeded') && this.importStates) {
                        if (pProgres.rowsFailed > 0) {
                            this.rowsFailed = pProgres.rowsFailed
                        }
                        this.importStates.setMessage(`${pProgres.rowsSucceeded + (0 ?? pProgres.rowsFailed)} ${$t("out of")} ${total}`);
                    }
                    if (pProgres.EOS && this.importStates &&  this.importStates.activeState) {
                        this.importStates.activeState.customMessage = ` ${total - this.rowsFailed} ${$t('rows')} `;
                        this.importStates.activeState.completed = true;
                        this.importStates.resetActive();

                        if (pOptions.onCompleted) pOptions.onCompleted.call(this, res, ...arguments);
                        this._dataObject.load();
                        this._signalR.stopConnection();
                    }
                    if (typeof this.onProgress == 'function') {
                         this.onProgress(pProgres);
                     }
                }

            });
        } catch (error: any) {
            if (pOptions.onError) pOptions.onError.call(this, error, ...arguments);
            alert(error);
        }
        this.completed = true;



    }
    private _isBehindProxy(): boolean {
        return (<HTMLMetaElement>document.querySelector("[name=o365-proxy-request]"))?.content === 'true';
    }
}

class ImportStates {
    states: Array<ImportState> = [];
    activeState: ImportState|null = null;


    constructor() {


        this.states.push(new ImportState("Uploading"));
        this.states.push(new ImportState("Parsing"));
        this.states.push(new ImportState("Committing"));
    }
    isActive(pState: ImportState) {
        if (!this.activeState) return false;
        if (this.activeState.name == pState.name) {
            return true;
        }
        return false;
    }

    setMessage(pMessage: string) {
        if (this.activeState)
            this.activeState.message = `${$t(this.activeState.name)} ${pMessage}`;
    }
    resetActive() {
        if (this.activeState)
            this.activeState.completed = true;
        this.activeState = null;
    }
    setActiveByName(pName: string) {
        //if(this.states.find(x=>x.name == pName))
        if (this.activeState)
            this.activeState.completed = true;
            this.activeState = this.states.find((x: ImportState) => x.name == pName);
    }

}
class ImportState {
    private _message: string = "";
    name: string;
    progress: number = 0;
    completed: boolean = false;
    customMessage: string = "";

    get message() {
        if (!this._message) {
            return $t(this.name);
        }
        if (this.completed && !this.customMessage) {
            return $t(this.name) + " " + $t("completed");
        }
        if (this.completed && this.customMessage) {
            return $t(this.name) + " " + this.customMessage + $t("completed") + ".";
        }

        return this._message;
    }



    set message(pMesssage: string) {
        this._message = pMesssage;
    }


    constructor(pName: string) {
        this.name = pName;
    }


}